import React from 'react'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import FeedbackButton from 'components/FeedbackButton'
import Banner from 'components/Banner'

const About = () => (
	<Layout>
		<SEO
			title="О компании — Парсинг сайтов «Парсик»"
			description="«Парсик» — команда опытных программистов. Предоставляем услуги по парсингу сайтов, товаров, интернет-магазинов"
			keywords="парсинг, сайт, товар, интернет-магазин, контакты, компания, услуга"
		/>
		<Banner title="О нас" description="Кто мы и чем занимаемся" image={{ name: 'home', width: 506, height: 392 }}>
			<FeedbackButton theme="light" size="lg">
				Связаться с нами
			</FeedbackButton>
		</Banner>
		<Sector>
			<div className="static-text mb-lg">
				<p>
					<strong>&laquo;Парсик&raquo;</strong>&nbsp;&mdash; проект созданный с&nbsp;целью облегчить жизнь маркетологам
					в&nbsp;поиске новых клиентов.
				</p>
				<p>
					Мы&nbsp;занимаемся париснгом сайтов, созданием баз компаний, сбором, структурированием, обработкой информации
					и&nbsp;ее&nbsp;форматированием в&nbsp;удобном виде для последующего использования.
				</p>
				<p>
					Цены на&nbsp;наши услуги на&nbsp;целый порядок ниже, чем в&nbsp;других веб-студиях. Это связано с&nbsp;тем,
					что клиент платит именно за&nbsp;результат. Наш недорогой офис располагается в&nbsp;небольшом городе, поэтому
					деньги направляются первую очередь на&nbsp;услугу, а&nbsp;не&nbsp;на&nbsp;содержание помещений, как это
					происходит в&nbsp;больших городах. Таким образом клиент инвестирует более грамотно и&nbsp;получает лучший
					сервис.
				</p>
				<p>Мы&nbsp;любим сложные и&nbsp;интересные проекты. Пишите, звоните нам. Обсудим ваши идеи!</p>
			</div>
			<div className="text-center">
				<FeedbackButton theme="primary" size="lg">
					Связаться с нами
				</FeedbackButton>
			</div>
		</Sector>
		{/*<Sector title="Отзывы о нас" color="gray" withoutContent titleCentered>*/}
		{/*	<Slider*/}
		{/*		data={[*/}
		{/*			{*/}
		{/*				// video: 'https://www.youtube.com/embed/UqwO9Oy66aU',*/}
		{/*				comment: `<p>Спасибо за создание сайта academyege.ru</p>`,*/}
		{/*				author: 'Иванов Иван Иванович',*/}
		{/*				job: 'Начальник производственного отдела<br/>ООО “Зеленоглазое такси”',*/}
		{/*			},*/}
		{/*		]}*/}
		{/*	/>*/}
		{/*</Sector>*/}
	</Layout>
)

export default About
